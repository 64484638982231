import { useStaticQuery, graphql } from 'gatsby';

export const useHeaderInfo = () => {
	const headerInfo = useStaticQuery(
		graphql`
            query GET_HEADER_INFO {
                allWordpressAcfOptions {
                    nodes {
                        options {
                            logo_header {
                                localFile {
                                    childImageSharp {
                                        fluid {
                                            ...GatsbyImageSharpFluid
                                        }
                                        fixed {
                                            ...GatsbyImageSharpFixed
                                        }
                                    }
                                }
                            }
                            header_working_hours
                            header_register_button_text
                            header_contact_number
                            header_banner_message_content
                            enable_header_banner
                        }
                    }
                }
            }
        `
	);
	return headerInfo.allWordpressAcfOptions.nodes[0].options;
};
