import { useStaticQuery, graphql } from 'gatsby';

export const useFooterContent = () => {
    const footerContent = useStaticQuery(
        graphql`
            query GET_FOOTER_CONTENT {
                allWordpressAcfOptions {
                    nodes {
                        options {
                            footer_address
                            footer_phone_number
                        }
                    }
                }
            }
        `
    );
    return footerContent.allWordpressAcfOptions.nodes[0].options;
};
