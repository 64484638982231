import { useStaticQuery, graphql } from 'gatsby';

export const useFooterMenuitems = () => {
    const AllFooterMenuItems = useStaticQuery(
        graphql`
            query GET_FOOTER_MENU_ITEMS {
                allWordpressWpApiMenusMenusItems(
                    filter: { slug: { eq: "footer-menu" } }
                ) {
                    edges {
                        node {
                            slug
                            name
                            items {
                                title
                                url
                                wordpress_id
                                type
                                wordpress_children {
                                    title
                                    wordpress_id
                                    url
                                    type
                                }
                            }
                        }
                    }
                }
            }
        `
    );
    return AllFooterMenuItems.allWordpressWpApiMenusMenusItems.edges[0].node
        .items;
};
