import React from 'react';
import { Link } from 'gatsby';
import HeaderMain from '../components/HeaderMain';
import Footer from '../components/Footer';
import TyImg from '../images/tyimg.jpg';
import '../styles/app.scss';

const ThankYou = () => (
    <>
        <HeaderMain />
        <section className="page-section smallestwdt centersec nopaddbottsec">
            <div className="container tywrap">
                <h2 className="bluetxt">Your message has been sent!</h2>
                <p className="biggertxt">
                    Our team will respond to your request promptly. Should you
                    not hear from us in 24 hours, please check your spam folder.
                    <strong>
                        If still no response, please resend the message.
                    </strong>
                </p>
                <Link to="/" target="_blank" class="wrtsbtn yellowbtn">
                    BACK TO HOME
                </Link>
                <img className="schoolsline" src={TyImg} alt="ty" />
            </div>
        </section>
        <Footer />
    </>
);

export default ThankYou;
