import React from 'react';
import { useHeaderInfo } from '../hooks/getHeaderInfo';

function BannerHead() {
	const data = useHeaderInfo();
	return (
		<section
			className={`header_banner ${data.enable_header_banner ? 'is_active' : ''}`}
			dangerouslySetInnerHTML={{
				__html: data.header_banner_message_content,
			}}
		/>
	);
}

export default BannerHead;
